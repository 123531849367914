import Alpine from 'alpinejs';

const emptyFormData =  () => ({
    search: '',
    winnersOnly: false,
    nomineeName: '',
    tvProgramTitle: '',
});

Alpine.data('awardsSearch', () => ({
    awardsCategoryOptions: [],
    tvNetworkOptions: [],
    productionCompanyOptions: [],
    isAdvancedSearch: false,

    formData: emptyFormData(),

    showResults: false,
    showResetButton: false,
    searchResults: [],
    pagination: [],
    totalHits: null,
    firstHit: null,
    lastHit: null,

    async init() {
        await this.fetchDropdownOptions();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.formData.search = urlParams.get('search') || '';
        this.formData.winnersOnly = urlParams.get('winnersOnly') === 'true';
        const page = !isNaN(urlParams.get('page'))  && urlParams.get('page') > 0 ? urlParams.get('page') : 1;
        this.fetchResults(page);
    },

    async fetchDropdownOptions() {
        const response = await fetch('/api/awards-search/dropdown-options');
        const data = await response.json();

        this.awardsCategoryOptions = data.awardsCategories.map((category, index) => ({
            id: 'cat-' + index,
            label: category.label,
        }));
        this.tvNetworkOptions = data.tvNetworks;
        this.productionCompanyOptions = data.productionCompanies;
    },

    resetForm() {
        this.formData = emptyFormData();
        this.$refs.awardsCategorySelect.value = [];
        this.$refs.tvNetworksSelect.value = [];
        this.$refs.productionCompaniesSelect.value = [];
        this.searchResults = [];
        this.pagination = [];
        this.totalHits = null;
        this.firstHit = null;
        this.lastHit = null;
        this.showResults = false;
        history.pushState('', '', '/');
    },

    async submitSearch() {
        await this.fetchResults();
    },

    async fetchResults(page = 1, scroll = false) {
        const searchParameters = this.isAdvancedSearch
            ? { ...this.formData,
                categories: this.mapCategorySelection(this.$refs.awardsCategorySelect.value),
                tvNetworks: this.$refs.tvNetworksSelect.value,
                productionCompanies: this.$refs.productionCompaniesSelect.value,
            }
            : { search: this.formData.search, winnersOnly: this.formData.winnersOnly };

        history.pushState('', '', '?'+new URLSearchParams({
            ...searchParameters,
            page
        }).toString());


        const response = await fetch('/api/awards-search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...searchParameters,
                page,
                baseUrl: window.location.pathname + '?' + new URLSearchParams(searchParameters).toString(),
            }),
        });

        const data = await response.json();


        this.searchResults = data.results;
        this.totalHits = data.totalHits;
        this.firstHit = data.firstHit;
        this.lastHit = data.lastHit
        this.pagination = data.pagination;
        this.showResults = true;

        // determine if formData is empty
        for (const key in this.formData) {
            if (this.formData[key]) {
                this.showResetButton = true;
            }
        }

        if (scroll && document.getElementById('awards-search-pagination-scroll-target')) {
            document.getElementById('awards-search-pagination-scroll-target').scrollIntoView({ behavior: 'smooth' });
        }
    },


    changePage(page) {
        if (page === null) {
            return;
        }

        this.fetchResults(page, true);
    },

    mapCategorySelection(selection) {
        if (!selection) {
            return selection;
        }

        return selection.map(category => {
            const option = this.awardsCategoryOptions.find(option => option.id === category);
            return option ? option.label : category;
        });
    },
}));
