import Alpine from 'alpinejs';

const membershipConfig = window.membershipConfig || {};
const MEMBERSHIP_BASE_URL = membershipConfig.baseUrl || '';
const INDICATOR_COOKIE_NAME = membershipConfig.authIndicatorCookieName || 'MEMBERSHIP_AS';

const LOGIN_URL = `${MEMBERSHIP_BASE_URL}/api/sso/v1/login`;
const LOGOUT_URL = `${MEMBERSHIP_BASE_URL}/api/sso/v1/logout`;
const CHECK_URL = `${MEMBERSHIP_BASE_URL}/api/sso/v1/check`;

const STORAGE_KEY_CURRENT_USER = 'member.auth.user';
const STORAGE_KEY_CURRENT_NAME = 'member.auth.name';
const STORAGE_KEY_AUTH_STATE = 'member.auth.state';
const STORAGE_KEY_LAST_CHECK = 'member.auth.last_check';

Alpine.store('auth', {
    loggedIn: false,

    /**
     * @type {{
     *      name: string | undefined,
     *      firstName: string | undefined,
     *      peerGroup: string | undefined,
     *      peerGroupCode: string | undefined,
     *      profilePicture: string | undefined,
     *      memberId: string | undefined,
     *      memberStatus: string | undefined,
     *      memberType: string | undefined,
     *      memberSince: string | undefined,
     *      emmyMagazineUrl: string | undefined,
     *      dashboardUrl: string | undefined,
     * }}
     */
    user: {},

    async init() {
        this._loadUserFromLocalStorage();

        if (this._needsAuthRefresh()) {
            await this.checkAuth();
        }
    },

    async login(username, password) {
        let response;

        try {
            response = await this._performRequest(LOGIN_URL, 'POST', {
                username,
                password,
            });
        } catch (error) {
            throw new Error('Error performing login. Please check your internet connection and try again.');
        }

        let responseData = {};

        try {
            responseData = await response.json();
        } catch (error) {
            // Can't parse response, using generic error message
        }

        if (response.status === 401) {
            throw new Error(responseData.message || 'Invalid username or password.');
        }

        if (response.status === 429) {
            throw new Error('Too many login attempts. Please try again later.');
        }

        if (!response.ok) {
            throw new Error('An error occurred while logging in. Please try again later.');
        }

        this._setLoggedIn(this._parseUserDataResponse(responseData));

        this._dispatchGlobal('auth-login', this.user);

        return this.user;
    },

    async logout() {
        try {
            await this._performRequest(LOGOUT_URL, 'POST');
        } catch (error) {
            throw new Error('Error performing logout. Please try again.');
        }

        this._setLoggedOut();

        this._dispatchGlobal('auth-logout');
    },

    async checkAuth() {
        const response = await this._performRequest(CHECK_URL, 'GET');

        if (!response.ok) {
            this._setLoggedOut();

            return;
        }

        const responseData = await response.json();

        this._setLoggedIn(this._parseUserDataResponse(responseData));

        return this.user;
    },

    //
    // Internal Methods
    //

    async _performRequest(url, method, body = null) {
        return await fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: body ? JSON.stringify(body) : null,
            credentials: 'include',
        });
    },

    _loadUserFromLocalStorage() {
        const storedUser = localStorage.getItem(STORAGE_KEY_CURRENT_USER);

        if (storedUser) {
            try {
                this.user = JSON.parse(storedUser);
                this.loggedIn = true;
            } catch (error) {
                console.warn('Error parsing stored user profile', error);
            }
        }
    },

    _parseUserDataResponse(responseData) {
        return {
            name: responseData.name,
            firstName: responseData.first_name,
            peerGroup: responseData.peer_group,
            peerGroupCode: responseData.peer_group_code,
            profilePicture: responseData.profile_picture,
            memberId: responseData.member_id,
            memberStatus: responseData.member_status,
            memberType: responseData.member_type,
            memberSince: responseData.member_since,
            emmyMagazineUrl: responseData.emmy_magazine_url,
            dashboardUrl: responseData.dashboard_url,
        };
    },

    _setLoggedIn(user) {
        this.loggedIn = true;
        this.user = user;

        localStorage.setItem(STORAGE_KEY_CURRENT_USER, JSON.stringify(this.user));
        localStorage.setItem(STORAGE_KEY_CURRENT_NAME, this.user.name || '');
        localStorage.setItem(STORAGE_KEY_AUTH_STATE, this._getMembershipAuthCookieValue());
        localStorage.setItem(STORAGE_KEY_LAST_CHECK, new Date().toISOString());
    },

    _setLoggedOut() {
        this.loggedIn = false;
        this.user = {};

        localStorage.removeItem(STORAGE_KEY_CURRENT_USER);
        localStorage.removeItem(STORAGE_KEY_CURRENT_NAME);
        localStorage.removeItem(STORAGE_KEY_AUTH_STATE);
        localStorage.removeItem(STORAGE_KEY_LAST_CHECK);
    },

    /**
     * Determine if we need to make a request to membership to check authentication status. This is the case when:
     *
     * - We are not logged in, but found a membership auth indicator cookie
     * - We are logged in, but no longer have a membership auth indicator cookie
     * - We are logged in, but the auth indicator cookie has changed
     * - We are logged in, but the last auth check was more than 10 minutes ago
     */
    _needsAuthRefresh() {
        if (!this.loggedIn) {
            return this._hasMembershipAuthCookie();
        }

        if (!this._hasMembershipAuthCookie()) {
            return true;
        }

        const lastAuthState = localStorage.getItem(STORAGE_KEY_AUTH_STATE);

        if (lastAuthState !== this._getMembershipAuthCookieValue()) {
            return true;
        }

        const lastAuthCheck = new Date(localStorage.getItem(STORAGE_KEY_LAST_CHECK));
        const diffInMinutes = Math.floor((new Date() - lastAuthCheck) / 1000 / 60);

        return diffInMinutes >= 10;
    },

    _hasMembershipAuthCookie() {
        return this._getMembershipAuthCookieValue() !== null;
    },

    _getMembershipAuthCookieValue() {
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        const membershipAsCookie = cookies.find(cookie => cookie.startsWith(INDICATOR_COOKIE_NAME + '='));

        if (!membershipAsCookie) {
            return null;
        }

        return membershipAsCookie.split('=')[1] || null;
    },

    _dispatchGlobal(name, detail) {
        window.dispatchEvent(new CustomEvent(name, { detail }));
    },
});
